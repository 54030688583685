/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordChangeRequiredApiModel
 */
export interface PasswordChangeRequiredApiModel {
    /**
     * 
     * @type {boolean}
     * @memberof PasswordChangeRequiredApiModel
     */
    isRequired?: boolean | null;
}

/**
 * Check if a given object implements the PasswordChangeRequiredApiModel interface.
 */
export function instanceOfPasswordChangeRequiredApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PasswordChangeRequiredApiModelFromJSON(json: any): PasswordChangeRequiredApiModel {
    return PasswordChangeRequiredApiModelFromJSONTyped(json, false);
}

export function PasswordChangeRequiredApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordChangeRequiredApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isRequired': !exists(json, 'isRequired') ? undefined : json['isRequired'],
    };
}

export function PasswordChangeRequiredApiModelToJSON(value?: PasswordChangeRequiredApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isRequired': value.isRequired,
    };
}

