/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PasswordChangeRequiredApiModel,
  PersonalDetailsApiModel,
  ProblemDetails,
  ProfileApiModel,
  ProfileExtendedApiModel,
  ResultApiModel,
  UpdatePasswordApiRequestModel,
} from '../models';
import {
    PasswordChangeRequiredApiModelFromJSON,
    PasswordChangeRequiredApiModelToJSON,
    PersonalDetailsApiModelFromJSON,
    PersonalDetailsApiModelToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ProfileApiModelFromJSON,
    ProfileApiModelToJSON,
    ProfileExtendedApiModelFromJSON,
    ProfileExtendedApiModelToJSON,
    ResultApiModelFromJSON,
    ResultApiModelToJSON,
    UpdatePasswordApiRequestModelFromJSON,
    UpdatePasswordApiRequestModelToJSON,
} from '../models';

export interface ProfilePasswordPutRequest {
    updatePasswordApiRequestModel?: UpdatePasswordApiRequestModel;
}

export interface ProfilePersonalDetailsPutRequest {
    personalDetailsApiModel?: PersonalDetailsApiModel;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     */
    async profileExtendedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileExtendedApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Profile/Extended`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileExtendedApiModelFromJSON(jsonValue));
    }

    /**
     */
    async profileExtendedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileExtendedApiModel> {
        const response = await this.profileExtendedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profileGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileApiModelFromJSON(jsonValue));
    }

    /**
     */
    async profileGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileApiModel> {
        const response = await this.profileGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profileGetPasswordChangeRequiredGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PasswordChangeRequiredApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Profile/GetPasswordChangeRequired`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordChangeRequiredApiModelFromJSON(jsonValue));
    }

    /**
     */
    async profileGetPasswordChangeRequiredGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PasswordChangeRequiredApiModel> {
        const response = await this.profileGetPasswordChangeRequiredGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profilePasswordPutRaw(requestParameters: ProfilePasswordPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Profile/Password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePasswordApiRequestModelToJSON(requestParameters.updatePasswordApiRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultApiModelFromJSON(jsonValue));
    }

    /**
     */
    async profilePasswordPut(requestParameters: ProfilePasswordPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultApiModel> {
        const response = await this.profilePasswordPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async profilePersonalDetailsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonalDetailsApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Profile/PersonalDetails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonalDetailsApiModelFromJSON(jsonValue));
    }

    /**
     */
    async profilePersonalDetailsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonalDetailsApiModel> {
        const response = await this.profilePersonalDetailsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async profilePersonalDetailsPutRaw(requestParameters: ProfilePersonalDetailsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Profile/PersonalDetails`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PersonalDetailsApiModelToJSON(requestParameters.personalDetailsApiModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultApiModelFromJSON(jsonValue));
    }

    /**
     */
    async profilePersonalDetailsPut(requestParameters: ProfilePersonalDetailsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultApiModel> {
        const response = await this.profilePersonalDetailsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
